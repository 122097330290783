import {
  getCartAttributeFeatureFlags,
  updateCartAttributeFeatureFlags,
} from './cart-attributes';
import {setFeatureFlags} from './feature-flags-store';
import {clearUrlFeatureFlags, getUrlFeatureFlags} from './url-params';
import {logError, logInfo} from '@/utilities/log';

/**
 * Evaluates and updates the feature flags based on the cart attributes and URL parameters.
 * This function reads the persisted feature flags from the cart attributes, updates them with
 * any feature flags present in the URL parameters, and then writes the evaluated feature flags
 * to the store. It also clears the URL feature flags and syncs the cart attributes with the
 * updated feature flags.
 *
 * @returns A promise that resolves when the feature flags have been evaluated and updated.
 */
export async function evaluateFeatureFlags() {
  try {
    const cartFlags = await getCartAttributeFeatureFlags();
    const urlFlags = getUrlFeatureFlags();
    const flags = new Map([...cartFlags, ...urlFlags]);
    setFeatureFlags(flags);
    await updateCartAttributeFeatureFlags(flags);
    clearUrlFeatureFlags();

    logInfo('EvaluatedFeatureFlags', {
      flags: Object.fromEntries(flags.entries()),
    });
  } catch (error) {
    logError(error, {message: 'ErrorEvaluatingFeatureFlags'});
  }
}
