import {logInfo} from '@/utilities/log';
import {prefix} from './feature-flags-store';

/**
 * Retrieves feature flags from the URL parameters.
 * A parameter is considered a feature flag if its key starts with the feature flag prefix.
 * Flags must evaluate to a string, number, or boolean.
 * If a flag cannot be parsed, it is ignored and an error is logged.
 * @returns A map containing the feature flags extracted from the URL parameters.
 */
export function getUrlFeatureFlags() {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const flags = new Map<string, string>();
  for (const [key, value] of params) {
    if (!key.startsWith(prefix)) continue;
    flags.set(key.replace(prefix, ''), value);
  }
  logInfo('FeatureFlagsFromUrl', {flags: Object.fromEntries(flags.entries())});
  return flags;
}

/**
 * Clears the URL feature flags by removing all query parameters that start with the feature flag prefix.
 * This ensures that the feature flags are not persisted in the URL,
 * so the customer is not aware of the feature flags being used.
 */
export function clearUrlFeatureFlags() {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  for (const key of params.keys()) {
    if (key.startsWith(prefix)) {
      params.delete(key);
    }
  }
  window.history.replaceState({}, '', url.toString());
}
